import { render, staticRenderFns } from "./PaymentPage.vue?vue&type=template&id=d8750e52&scoped=true"
import script from "./PaymentPage.vue?vue&type=script&lang=js"
export * from "./PaymentPage.vue?vue&type=script&lang=js"
import style0 from "./PaymentPage.vue?vue&type=style&index=0&id=d8750e52&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8750e52",
  null
  
)

export default component.exports