<template>
  <div class="container">
    <div v-if="loading" class="overlay">
      <div class="spinner"></div>
    </div>
    <div class="row mt-10 justify-content-center">
      <div class="col-md-6">
        <div>
          <img alt="Lpc logo" src="./assets/main.png" height="290" style="padding-left: 120px; padding-top: 50px;"/>
          <HelloWorld msg=" "/>
          <div v-if="detailsProvided && verified">
            <div class="mb-3">
              <label for="invoiceSelect" class="form-label">Unpaid Invoice/s: ({{ invoices.length }})</label>
              <select id="invoiceSelect" class="form-control" v-model="userDetails.selectedInvoice" required>
                <option value="" disabled>Select an invoice</option>
                <option v-for="invoice in invoices" :key="invoice.inv" :value="invoice.inv">
                  R{{ invoice.inv_total }} for {{ invoice.inv_desc }}
                </option>
              </select>
            </div>

            <div v-if="selectedInvoice.inv">
              <span style="color: #593A14;"><b>Invoice Details:</b> </span>
              <div class="row">
              <span style="color: #593A14;"><b>No:</b>
                <span style="padding-left: 72px !important;">
                 {{ selectedInvoice.inv ? selectedInvoice.inv : 'null' }}
               </span>
              </span>

                <span style="color: #593A14;"><b>Province:</b>
                <span style="padding-left: 30px !important;">
                {{ selectedInvoice.province ? selectedInvoice.province.toUpperCase() : 'N/A' }}
                </span>
              </span>

                <span style="color: #593A14;"><b>Amount:</b>
                <span style="padding-left: 35px !important;">
                R {{ selectedInvoice.inv_total }}
                </span>
              </span>

                <span style="color: #593A14;"><b>Fee Type:</b>
                <span style="padding-left: 30px !important;">
                {{ selectedInvoice.inv_desc ? selectedInvoice.inv_desc.toUpperCase() : 'N/A' }}
                </span>
              </span>

                <span style="color: #593A14;"><b>Due date:</b>
                <span style="padding-left: 30px !important;">
                {{ formattedDueDate }}
                </span>
              </span>
              </div>
            </div>
            <br>
          </div>
          <div v-else>
            <div class="mb-3">
              <label for="name" class="form-label">Practitioner No</label>
              <input type="password" class="form-control" id="name" v-model="userDetails.memno" required>
              <span class="text-danger" v-if="inValidPassword">You provided invalid Practitioner ID... try again</span>
              <br>
              <button
                  @click="verifyMember"
                  style="float: right; width: 20%; border-radius: 20px;" type="submit"
                  class="btn btn-success btn-block"
              >
                Verify
              </button>
            </div>
            <br>
          </div>

          <span v-if="!detailsProvided" style="color: red; font-weight: normal !important;">
           The URL Provided Incomplete Data, Please Use The Gateway Form To Fill In User Details
          </span>
          <br>
          <span style="color: #9F5B08; padding-left: 30%; ">
            <a style="color: #9F5B08;" :href="pdfUrl" target="_blank" download>
            <b>Click Here to Download Refund Form</b>
              <i style="color: red; padding-left: 5px;" class="fas fa-file-pdf"></i>
            </a>
          </span>

          <br>
          <hr>
        </div>
      </div>

      <div class="col-md-6" v-if="verified && selectedInvoice.inv">
        <payment-page :invData="selectedInvoice" />
      </div>
    </div>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue';
import PaymentPage from './components/PaymentPage.vue';
import moment from "moment";
import axios from "axios";

export default {
  name: 'App',
  components: {
    HelloWorld,
    PaymentPage
  },
  data() {
    return {
      userDetails: {
        name: '',
        province: '',
        amount: '',
        feeType: '',
        memno: null,
        selectedInvoice: null
      },
      pdfUrl: 'https://www.fidfund.co.za/wp-content/uploads/2023/06/Revised-Application-for-Refund-of-Bank-Charges-and-Audit-Fees-Form.pdf',
      detailsProvided: true,
      verified: false,
      invoice: {},
      invoices: [],
      inValidPassword: false,
      loading: false
    };
  },
  async mounted() {
    this.loading = true;
    const urlParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlParams.entries());

    if (params.invData) {
      await this.fetchInvoiceData(params.invData).catch(() => {
        alert('Error While Fetching Invoice');
      });
    } else {
      this.detailsProvided = false;
    }
    this.loading = false;
  },
  computed: {
    formattedDueDate() {
      return this.selectedInvoice.inv_date ? moment(this.selectedInvoice.inv_date).format('DD MMMM YYYY') : 'N/A';
    },
    unpaidInvoices() {
      return this.invoices.filter(invoice => invoice.inv_paid === 0);
    },
    selectedInvoice() {
      const selected = this.invoices.find(invoice => invoice.inv === this.userDetails.selectedInvoice) || {};

      selected.fullnames = `${selected.firstname} ${selected.surname}`
      selected.province = 'Gauteng';
      return selected;
    }
  },
  methods: {
    verifyMember() {
      if (this.userDetails.memno === this.invoice.memno) {
        this.inValidPassword = false;
        this.verified = true;
      } else {
        this.inValidPassword = true;
      }
    },
    async fetchInvoiceData(id) {
      const apiUrl = `https://us-central1-payprov-532bb.cloudfunctions.net/api/fetchInvoiceData?id=${id}`;

      try {
        const response = await axios.get(apiUrl);
        this.invoice = response.data[0];
        this.invoices = response.data.filter(invoice => invoice.inv_paid === 0);
        this.userDetails = this.invoice;
      } catch (error) {
        console.error('API call error:', error);
      }
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
