<template>
  <div class="container mt-5">
    <div class="card">
      <div class="card-header">
        <h2 align="center">Payment <span style="color: rgb(43, 92, 226);">Gateway</span></h2>
      </div>
      <div class="card-body" style="border-radius: 10px !important;">
        <form @submit.prevent="handlePayment">
          <div class="mb-3">
            <label for="name" class="form-label">Full Names</label>
            <input type="text" class="form-control" id="name" v-model="localData.fullnames" required readonly>
          </div>

          <div class="mb-3">
            <label for="email" class="form-label">Email Address</label>
            <input type="text" class="form-control" id="email" v-model="localData.email" required>
          </div>

          <div class="mb-3">
            <label for="province" class="form-label">Province</label>
            <select class="form-control" id="province" v-model="localData.province" required disabled>
              <option value="" disabled>Select a province</option>
              <option v-for="(province, index) in provinces" :key="index" :value="province">{{ province }}</option>
            </select>
          </div>

          <div class="mb-3">
            <label for="amount" class="form-label">Amount (ZAR)</label>
            <input type="number" class="form-control" id="amount" v-model="localData.inv_total" step="0.01" min="0"
                   required readonly>
          </div>

          <div class="mb-3">
            <paystack
                style="margin-left: 5px; float: right; width: 20%; border-radius: 20px;"
                :amount="localData.inv_total * 100"
                :email="localData.email"
                :paystackkey="PUBLIC_KEY"
                :reference="reference"
                :callback="processPayment"
                :close="close"
                :embed="false"
                currency="ZAR"
                class="btn btn-primary btn-block"
            >
              Pay Now
            </paystack>
            <button style="float: right; width: 20%; border-radius: 20px;" type="submit"
                    class="btn btn-danger btn-block">Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import paystack from "vue-paystack";
import axios from "axios";

export default {
  components: {
    paystack
  },
  props: {
    invData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      localData: {...this.invData},
      PUBLIC_KEY: '',
      provinces: [
        "Eastern Cape", "Free State", "Gauteng", "KwaZulu-Natal",
        "Limpopo", "Mpumalanga", "Northern Cape", "North West", "Western Cape"
      ]
    };
  },
  computed: {
    reference() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    }
  },
  watch: {
    invData: {
      handler(newVal) {
        this.localData = {...newVal};
      },
      deep: true
    }
  },
  mounted() {
    this.setPublicKey();
  },
  methods: {
    setPublicKey() {
      const province = this.localData.inv_db;

      switch (province) {
        case 'GAUDAT':
          this.PUBLIC_KEY = process.env.VUE_APP_GAUTENG_PUBLIC_KEY;
          break;
        case 'MPUDAT':
          this.PUBLIC_KEY = process.env.VUE_APP_MPUMALANGA_PUBLIC_KEY;
          break;
        case 'NWPDAT':
          this.PUBLIC_KEY = process.env.VUE_APP_NORTH_WEST_PUBLIC_KEY;
          break;
        case 'LIMDAT':
          this.PUBLIC_KEY = process.env.VUE_APP_LIMPOPO_PUBLIC_KEY;
          break;
        case 'KZNDAT':
          this.PUBLIC_KEY = process.env.VUE_APP_KWAZULU_NATAL_PUBLIC_KEY;
          break;
        case 'NWTDAT':
          this.PUBLIC_KEY = process.env.VUE_APP_NORTHERN_CAPE_PUBLIC_KEY;
          break;
        case 'WTCDAT':
          this.PUBLIC_KEY = process.env.VUE_APP_WESTERN_CAPE_PUBLIC_KEY;
          break;
        case 'EASDAT':
          this.PUBLIC_KEY = process.env.VUE_APP_EASTERN_CAPE_PUBLIC_KEY;
          break;
        case 'FREDAT':
          this.PUBLIC_KEY = process.env.VUE_APP_FREE_STATE_PUBLIC_KEY;
          break;
        default:
          console.error("Unknown province code:", province);
          this.PUBLIC_KEY = "default_public_key";
          break;
      }
    },

    async processPayment(response) {
      if (response.status === 'success') {

        const apiUrl = `https://api.lpc.org.za:3000/api/invPayment`;
        const apiKey = 'a09d674f1e16a410a8f668222872c8c01dc6933b3391923a10bf19c40fc885f6';

        const payload = {
          memno: this.localData.memno,
          amt: (this.localData.inv_total * 100),
          ref: this.localData.inv_db,
          inv: this.localData.inv
        };

        try {
         await axios.post(apiUrl, payload, {

           headers: {
             'x-api-key': apiKey,
             'Content-Type': 'application/json'
           }

         }).then((response) => {
           console.log( response.data );
           alert('Payment Updated to LPC System: ' + response.data);
         })

        } catch (error) {
          console.error('API call error:', error);
        }

        alert('Payment successful!');
      } else {
        alert('Payment failed. Please try again.');
        console.log('Payment failed:', response);
      }
    },
    close() {
      console.log("You closed the checkout page");
    },
    async handlePayment() {

    }
  }
};
</script>

<style scoped>
.container {
  max-width: 600px;
}
</style>
